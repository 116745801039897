import React from "react";

function BookChaptersList ( { bookIDSlug, bookChapters, fontColor } ) {
    // Get the current time and adjust to UTC
    const now = new Date();
    // const nowUTC = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));

    // Function to calculate the time ago
    const getTimeAgo = (pastDate, currentDate) => {
        const msPerMinute = 60 * 1000;
        const msPerHour = msPerMinute * 60;
        const msPerDay = msPerHour * 24;
        const elapsed = currentDate - pastDate;

        if (elapsed < msPerMinute) {
            return Math.round(elapsed / 1000) + ' seconds';   
        } else if (elapsed < msPerHour) {
            return Math.round(elapsed / msPerMinute) + ' minutes';   
        } else if (elapsed < msPerDay ) {
            return Math.round(elapsed / msPerHour ) + ' hours';  
        } else {
            return Math.round(elapsed / msPerDay) + ' days';   
        }
    };

    return (
        <div className={`flex flex-col border-tertiary break-words w-full ${fontColor ? fontColor : ''}`}>
            <ol className="flex-grow grid grid-cols-1 lg:grid-cols-2 gap-4">
                {bookChapters.map((chapter, index) => {
                    const uploadedTime = new Date(chapter.uploaded_time);
                    // const uploadedTimeUTC = new Date(chapter.uploaded_time);
                    const elapsed = now - uploadedTime;
                    const msPerDay = 24 * 60 * 60 * 1000;

                    return (
                        <li key={chapter.chapter_id} className="border-tertiary border-2 p-2 list-inside">
                            <div className="grid grid-cols-6 items-center h-full">
                                <a href={`/${bookIDSlug}/${chapter.chapter_id}`} className="col-span-4 hover:underline">
                                    <div className="flex items-center">
                                        <span className="mr-1 whitespace-nowrap">{index + 1}.</span>
                                        <div className="truncate">{chapter.title}</div>
                                    </div>
                                </a>
                                <div className="col-span-2 flex justify-self-end items-center gap-1">
                                    <p className="text-xs text-center opacity-80">{getTimeAgo(uploadedTime, now)}</p>
                                    <div className={`font-bold text-[10px] text-center text-white p-0.5 rounded-full w-fit ${elapsed < 20*msPerDay ? `border border-red-800 bg-red-500` : `hidden`}`}>
                                        New
                                    </div>
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ol>
        </div>
    )
}

export default BookChaptersList;