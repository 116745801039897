import React, { useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { AuthContext } from "./users/AuthContext";
import axios from "axios";
import LikeButton from "./buttons/LikeButton";

function BookDetails ( { bookData } ) {
    const {book_id, english_book_name, author, cover_image, 
        book_word_count, raw_source, description, book_tl_note, 
        last_updated, original_book_name, completion_statuses, diversities, 
        endings, genres, original_languages, settings, tags,
        part_of_series, shared_universe, similar_theme} = bookData;

    const [isRevealed, setIsRevealed] = useState(false);
    const toggleReveal = () => {
        setIsRevealed(!isRevealed);
    };

    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [popupCallback, setPopupCallback] = useState(null);
    const [showLogin, setShowLogin] = useState(false);

    const [liked, setLiked] = useState(null);
    const [likeCount, setLikeCount] = useState(null);
    const [isLikeSubmitting, setIsLikeSubmitting] = useState(false);

    const queryClient = useQueryClient();
    const { user, refreshToken } = useContext(AuthContext);

    useEffect(() => {
        queryClient.invalidateQueries(['like-books', book_id]);
    }, [queryClient]);

    const fetchBookLike = async () => {
        if (user) {
            await refreshToken();
        }
        
        const endpoint = user 
        ? `${process.env.REACT_APP_BASE_URL}/api/like/show-user-like-count` 
        : `${process.env.REACT_APP_BASE_URL}/api/like/show-visitor-like-count`;
        
        const response = await axios.get(endpoint, {
            params: {book_id}
        });
    
        setLiked(response.data.isLiked);
        setLikeCount(response.data.likeCount.like_count);
        return response.data;
    };

    const { data, error, isLoading } = useQuery(['like-books', book_id], fetchBookLike, {
        refetchOnWindowFocus: false,
    });

    const handleLike = async () => {
        setIsLikeSubmitting(true);
        if (!user) {
            setPopupMessage('You must log in to like a story.');
            setPopupCallback(null);
            setShowLogin(true);
            setShowPopup(true);
            setTimeout(() => setIsLikeSubmitting(false), 500); // Re-enable the button after 0.5 second
            return;
        }

        try {
            await refreshToken();

            setLiked(!liked);
            setLikeCount(liked ? likeCount - 1 : likeCount + 1);
            
            const endpoint = liked
                ? `${process.env.REACT_APP_BASE_URL}/api/like/remove-like-book`
                : `${process.env.REACT_APP_BASE_URL}/api/like/like-book`;
            await axios.post(endpoint, { book_id: book_id });
        } catch (error) {
            console.error('Error liking/unliking story:', error);
            if (error.response && error.response.status === 403 || error.response.status === 429) {
                setPopupMessage(error.response.data.message);
                setPopupCallback(null);
                setShowPopup(true);
            }
        } finally {
            setTimeout(() => setIsLikeSubmitting(false), 500); // Re-enable the button after 0.5 second
        }
    };

    const formattedDescription = description ? description.replace(/\n/g, '<br/>') : '';
    const formattedTLNote = book_tl_note ? book_tl_note.replace(/\n/g, '<br/>') : '';

    const createLinks = (items, category) => {
        return items.split(',').map((item, index) => (
            <span key={index}>
                <a href={`${category}/${item.trim()}`} className="underline cursor-pointer">
                    {item}
                </a>
                {index < items.split(',').length - 1 && ', '}
            </span>
        ));
    };

    const handlePopupClose = () => {
        if(showLogin == true) {
            setShowPopup(false);
            setShowLogin(false);
        } else {
            setShowPopup(false);
        }
    }
    
    const handlePopupLogin = () => {
        if(showLogin == true) {
            setShowPopup(false);
            setShowLogin(false);
            window.open('/user/login', '_blank');
        } else {
            setShowPopup(false);
        }
    }

    // Helper function to generate slugs
    const generateSlug = (bookId, bookName) => {
        let slug = `${bookId} - ${bookName}`;
        slug = slug.replace(/[^a-zA-Z0-9 -]/g, "");
        slug = slug.replace(/[\s-]+/g, '-');
        slug = slug.toLowerCase();
        return slug;
    };

    // Render function to display related books
    const renderRelatedBooks = (related_books, relationship_type) => {
        if (!related_books) return null;
        
        const books = related_books.split(';').map(book => {
            const firstHyphenIndex = book.indexOf('-');
            const related_book_id = book.substring(0, firstHyphenIndex);
            const related_book_name = book.substring(firstHyphenIndex + 1);
            const slug = generateSlug(related_book_id, related_book_name);
            
            return (
                <li key={related_book_id} className="list-decimal list-inside">
                    {
                        related_book_id == book_id ?
                        <span>{related_book_name}</span> :
                        <a href={`/${slug}`} target="_blank" className="underline hover:no-underline">
                            {related_book_name}
                        </a>
                    }
                </li>
            );
        });
        
        return (
            <div className="flex flex-col p-2 border-tertiary border-2 break-words w-full">
                <p className="underline">{relationship_type}:</p>
                <ol>{books}</ol>
            </div>
        );
    };

    return (
        <div className="flex flex-col justify-center gap-2 w-full">
            <div className="flex flex-col lg:flex-row p-2 border-tertiary border-2">
                <div className="flex m-5 justify-center items-center">
                    <img src={cover_image} alt={"Cover page of " + english_book_name} loading="lazy" className="object-cover max-w-36 max-h-48" />
                </div>
                <div className="flex flex-col break-words max-w-xs lg:max-w-full">
                    <h2 className="text-xl font-bold text-center lg:text-left">{english_book_name}</h2>
                    <p className="text-md text-center lg:text-left">by {author}</p>
                    <p className="text-md text-center lg:text-left">{book_word_count} words</p>
                    {/* <a href={raw_source} className="text-md underline text-left">Raw Source</a> */}
                    <br />
                    {original_book_name && <p className="text-md text-left">Associated Names: {original_book_name}</p>}
                    <p className="text-md text-left">Completion Status: {completion_statuses}</p>
                    <p className="text-md text-left">Original Language: {original_languages}</p>
                    <span className="text-md text-left">
                        Diversity:
                        &nbsp;
                        <a href={`/diversity/${diversities}`} className="underline cursor-pointer">{diversities}</a>
                    </span>
                    
                    {endings &&
                        <div className="flex">
                            <p className="text-md text-left">Endings:</p>
                            &nbsp;
                            <span 
                                className={`max-w-fit cursor-pointer ${isRevealed ? 'bg-transparent' : 'bg-tertiary text-transparent'} text-md text-left`}
                                onClick={toggleReveal}
                            >
                                {endings}
                            </span>
                        </div>
                    }
                    <p className="text-md text-left">Genres: {createLinks(genres, 'genre')}</p>
                    <p className="text-md text-left">Settings: {createLinks(settings, 'setting')}</p>
                    <p className="text-md text-left">Tags: {createLinks(tags, 'tag')}</p>
                    <br />
                    {!isLoading && 
                        <div className="flex flex-row items-center gap-2">
                            <LikeButton
                                liked={liked}
                                onClick={handleLike}
                                size={17} // Adjust the size as needed
                                isSubmitting={isLikeSubmitting}
                            />
                            <span className="text-md">{likeCount > 0 ? `${likeCount} people like this story!` : `Be the first to like this story!`}</span>
                        </div>
                    }
                </div>
            </div>

            <div className="flex flex-col p-2 border-tertiary border-2 break-words w-full">
                <p className="underline">Description:</p>
                <p dangerouslySetInnerHTML={{ __html: formattedDescription }}></p>
            </div>

            {book_tl_note &&
                <div className="flex flex-col p-2 border-tertiary border-2 break-words w-full">
                    <p className="underline">Translator Note:</p>
                    <p dangerouslySetInnerHTML={{ __html: formattedTLNote }}></p>
                </div>
            }

            {renderRelatedBooks(part_of_series, "Series")}
            {renderRelatedBooks(shared_universe, "Related Story")}
            {renderRelatedBooks(similar_theme, "Similar Theme")}

            {showPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-4 rounded shadow-lg text-center text-tertiary">
                        <p>{popupMessage}</p>
                        {popupCallback ? (
                        <div className="mt-4">
                            <button onClick={popupCallback} className="bg-red-500 text-white px-4 py-2 rounded mr-2">Yes</button>
                            <button onClick={() => setShowPopup(false)} className="bg-gray-500 text-white px-4 py-2 rounded">No</button>
                        </div>
                        ) : (
                            <div className='flex justify-around'>
                                {(showLogin &&
                                    <button onClick={handlePopupLogin} className="bg-red-400 text-white py-2 px-4 rounded-md mt-4 hover:bg-tertiary transition duration-300">
                                    Log In
                                    </button>
                                )}
                                <button onClick={handlePopupClose} className="bg-gray-400 text-white py-2 px-4 rounded-md mt-4 hover:bg-tertiary transition duration-300">
                                    Close
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}
            
        </div>
    )
}

export default BookDetails;